.mint-button {
  font-family: RedHat, serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0;
  text-align: center;
  &--small {
    font-size: 24px;
    line-height: 48px;
  }
}