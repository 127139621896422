
.mint-section {
  min-height: 194px;
  max-width: 1014px;
  border-radius: 16px;
  background: linear-gradient(92.97deg, #FFFFFF 0.83%, #FCEBBD 51.1%, #FFFFFF 100.43%);
  box-shadow: 0 4px 10px 4px rgba(222, 226, 227, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0;
    text-align: left;
    font-family: RedHat, serif;

    font-size: 20px;
    line-height: 26px;
    margin-bottom: 32px;
  }
  @media (min-width: 560px) {
    flex-direction: row;
    justify-content: space-around;
    min-height: 144px;
    p {
      font-size: 24px;
      line-height: 48px;
      margin-bottom: 0;
    }
  }
  @media (min-width: 768px) {
    min-height: 252px;
    p {
      font-size: 36px;
      line-height: 42px;
    }
  }
}