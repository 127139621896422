.footer-section {
  position: relative;
  img {
    height: 210px;
    @media (min-width: 560px) {
      height: 287px;
    }
    @media (min-width: 768px) {
      height: 540px;
    }
  }
  &__text {
    position: absolute;
    bottom: 36px;
    left: 16px;
    p {
      margin-top: 8px;
      font-family: RedHat, serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0;
      text-align: left;
      color: #FFFFFF;
    }
    svg {
      width: 138px;
      height: 16px;
    }
    @media (min-width: 560px) {
      bottom: 34px;
      left: 32px;
      p {
        margin-top: 16px;
        font-size: 18px;
        line-height: 24px;
      }
      svg {
        width: 274px;
        height: 32px;
      }
    }
    @media (min-width: 768px) {
      bottom: 44px;
      left: 64px;
      p {
        margin-top: 24px;
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}