.earn-section {
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  @media (min-width: 568px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 900px) {
    justify-content: space-between;
  }
  &__box {
    background: linear-gradient(238.89deg, #fffff1 19.72%, #ffffff 89.2%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 48px 16px;
    border-radius: 16px;
    order: 2;
    img {
      max-width: 74px;
    }
    span {
      display: none;
    }
    .plus-icon,
    .equal-icon {
      width: 16px;
      height: 16px;
    }
    .question-icon {
      width: 51px;
      height: 18px;
    }
    @media (min-width: 568px) {
      margin-inline-end: 32px;
      flex-direction: column;
      align-items: center;
      max-width: 254px;
      order: 1;
      img {
        max-width: 158px;
      }
      span {
        font-size: 18px;
        display: block;
      }
      .plus-icon,
      .equal-icon {
        width: 32px;
        height: 32px;
      }
      .question-icon {
        margin-top: 24px;
        width: 98px;
        height: 36px;
      }
    }
    @media (min-width: 900px) {
      padding: 48px;
      margin-inline-end: 0;
      max-width: 332px;
      img {
        max-width: 236px;
      }
      span {
        font-size: 24px;
      }
      .question-icon {
        width: 142px;
        height: 52px;
      }
    }
  }
  &__text {
    order: 1;
    @media (min-width: 568px) {
      order: 2;
    }
  }
  &__title {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0;
    text-align: left;
    @media (min-width: 1140px) {
      font-size: 48px;
      line-height: 63.5px;
    }
  }
  &__desc {
    margin-top: 16px;
    max-width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 32px;
    @media (min-width: 568px) {
      max-width: 254px;
      margin-bottom: 0;
    }
    @media (min-width: 900px) {
      max-width: 448px;
      font-size: 24px;
      line-height: 48px;
    }
  }
}
