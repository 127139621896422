.first-view-section {
  max-width: 1144px;
  position: relative;
  border-radius: 32px;
  overflow: hidden;
  img {
    width: 100%;
    height: 528px;
    object-fit: cover;
    @media (min-width: 560px) {
      height: 989px;
    }
    @media (min-width: 768px) {
      height: 680px;
    }
  }
  &__text {
    background: white;
    position: absolute;
    left: 0;
    bottom: 159px;
    height: 128px;
    width: 100%;
    max-width: 253px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-inline-start: 16px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    @media (min-width: 560px) {
      height: 196px;
      max-width: 434px;
      padding-inline-start: 34px;
      bottom: 298px;
    }
    @media (min-width: 768px) {
      height: 224px;
      max-width: 680px;
      padding-inline-start: 48px;
      bottom: 205px;
    }
    svg {
      width: 208px;
      height: 24px;
      @media (min-width: 560px) {
        width: 275px;
        height: 32px;
      }
      @media (min-width: 768px) {
        width: 514px;
        height: 60px;
      }
    }
    p {
      margin-top: 24px;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      margin-inline-start: 8px;
      @media (min-width: 560px) {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }
}