.road-map-section {
  background: white;
  max-width: 1144px;
  margin: 48px auto;
  border-radius: 8px;
  padding: 48px 16px;
  box-shadow: 0 4px 10px 4px rgba(222, 226, 227, 0.25);
  @media (min-width: 568px) {
    margin: 55px auto;
    padding: 48px;
  }
  @media (min-width: 900px) {
    margin: 55px auto;
    padding: 48px 0;
  }
  .fadein {
    animation: fadeIn 0.9s 1;
    animation-fill-mode: forwards;
  }
  &__row {
    display: flex;
    gap: 60px;
    transition: 500ms all ease-in-out;
    align-items: center;
    opacity: 0;
    @media (max-width: 576px) {
      padding-right: 0 !important;
    }
    @media (max-width: 900px) {
      justify-content: flex-start !important;
      padding-left: 0 !important;
    }
    @media (min-width: 900px) {
      margin-bottom: 72px;
    }
    svg {
      margin-top: 8px;
    }
    &--reverse {
      margin-inline-start: 32px;
      svg {
        margin: 8px 0 0 48px;
      }
    }
    &--last {
      svg {
        position: absolute;
        top: -24px;
        left: -24px;
        width: 48px;
        height: 48px;
      }
      @media (min-width: 900px) {
        margin-inline-start: 32px;
        svg {
          top: -48px;
          left: -32px;
          width: 132px;
          height: 132px;
        }
      }
    }
  }
  .third-row {
    svg {
      margin-top: 108px;
    }
  }
  .first-row {
    svg {
      margin-top: 32px;
    }
  }
  &__list {
    position: relative;
    list-style: unset;
    max-width: 227px;
    text-align: left;
    @media (min-width: 576px) {
      max-width: 344px;
    }
    @media (min-width: 900px) {
      max-width: 388px;
    }
    li {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0;
      text-align: left;
      line-height: 32px;
      @media (min-width: 900px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 8px;
      }
    }
    span {
      position: absolute;
      font-size: 64px;
      opacity: 0.2;
      line-height: 16px;
      top: 0;
      left: -24px;
      @media (min-width: 900px) {
        font-size: 100px;
        left: -33px;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
