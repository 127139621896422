@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: RedHat;
  src: url('../fonts/RedHat.ttf') format('truetype');
  font-weight: 400;
}

html {
  background-color: #F3F8FA;
  scroll-behavior: smooth;
}
.app {
  font-family: RedHat, serif;
  padding-top: 0;
  -webkit-tap-highlight-color: transparent;
}