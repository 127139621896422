
.treasure-section {
  min-height: 524px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 900px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  @media (min-width: 1144px) {
    padding: 16px 0;
  }
  //flex items-center justify-between
  img {
    width: 100%;
    max-width: 332px;
    margin: 32px auto 16px auto;
    @media (min-width: 900px) {
      margin: 0 0 0 32px;
    }
  }
  &__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0;
    text-align: left;
    @media (min-width: 560px) {
      font-size: 36px;
      line-height: 48px;
    }
    @media (min-width: 900px) {
      font-size: 48px;
      line-height: 64px;
    }
  }
  &__desc {
    margin-top: 16px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0;
    text-align: justify;
    max-width: 680px;
    @media (min-width: 560px) {
      margin-top: 32px;
      font-size: 24px;
      line-height: 36px;
    }
  }
}