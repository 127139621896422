.travel-bear-section {
  box-shadow: 0 4px 10px 4px rgba(222, 226, 227, 0.25);
  .bear-carousel {
    .owl-item {
      transition: all 100ms ease-in-out;
      opacity: 0.7;
      border-radius: 16px;
      overflow: hidden;
      img {
        width: 74px;
        height: 74px;
      }
      @media (min-width: 560px) {
        img {
          width: 128px;
          height: 128px;
        }
      }
      @media (min-width: 768px) {
        img {
          width: 164px;
          height: 164px;
        }
      }
      @media (min-width: 1200px) {
        align-items: flex-end;
        display: flex;
        img {
          width: 216px;
          height: 216px;
        }
      }
      &.center {
        box-shadow: 0 4px 10px 10px rgba(251, 203, 75, 0.35);
        opacity: 1;
        width: 164px !important;
        height: 164px;
        display: flex;
        justify-content: center;
        img {
          max-width: 164px;
          width: 164px !important;
          height: 164px;
        }
        @media (min-width: 560px) {
          width: 200px !important;
          height: 200px;
          img {
            max-width: 200px;
            width: 200px !important;
            height: 200px;
          }
        }
        @media (min-width: 800px) {
          width: 254px !important;
          height: 254px;
          img {
            max-width: 254px;
            width: 254px !important;
            height: 254px;
          }
        }
        @media (min-width: 1000px) {
          width: 290px !important;
          height: 290px;
          img {
            max-width: 290px;
            width: 290px !important;
            height: 290px;
          }
        }
        @media (min-width: 1438px) {
          width: 332px !important;
          height: 332px;
          img {
            max-width: 332px;
            width: 332px !important;
            height: 332px;
          }
        }
      }
    }
  }
}
